import {LoadingOutlined} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import useMediaQuery from "use-media-antd-query";
import styls from "./index.module.scss";
import {Spin} from "antd";
import {useEffect, useState} from "react";

const LineRegisterCushion: React.FC = () => {
  const colSize = useMediaQuery();
  const isMobile = colSize === "xs";
  const { t } = useTranslation();
  const newMemberRegistration = t("auth.logon.newMemberRegistration");

  const [hasRedirected, setHasRedirected] = useState(false);

  useEffect(() => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const extendedItems = process.env.REACT_APP_EXTENDED_ITEMS;
      const socialPlusLoginUrl = process.env.REACT_APP_SOCIAL_PLUS_LINE_LOGIN;

      if (!baseUrl || !extendedItems || !socialPlusLoginUrl) {
          console.error("Missing required environment variables");
          return;
      }

      const params = new URLSearchParams({
          callback: `${baseUrl}/auth/login/callback`,
          extended_items: extendedItems
      });

      const socialPlusAuthorizeUrl = `${socialPlusLoginUrl}?${params.toString()}`;

      // If we haven't redirected yet, set a timeout to redirect
      if (!hasRedirected) {
          const timer = setTimeout(() => {
              window.location.href = socialPlusAuthorizeUrl; // Redirect
              setHasRedirected(true); // Prevent further redirects
          }, 1000);

          return () => clearTimeout(timer); // Cleanup
      }
  }, [hasRedirected]); // Depend on hasRedirected


  return (
      <div className={styls.calogon_success_body}>
        <div className={styls.calogon_success_context}>
          <div
              className={styls.calogon_success_title}
              style={{
                padding: isMobile ? "32px 30px" : "32px 0",
                textAlign: isMobile ? "left" : "center",
              }}
          >
            {newMemberRegistration}
          </div>
          <div
              className={styls.calogon_success_message_context}
              style={{
                padding: isMobile ? "31px 30px 49px" : "31px 0 49px",
                height: "300px",
                textAlign: "center",
              }}
          >
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: "#F08984" }} spin />} />
          </div>
        </div>
      </div>
  );
};

export default LineRegisterCushion;
